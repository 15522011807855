<template>
  <el-dialog
    :model-value="modelValue"
    :close-on-click-modal="true"
    width="60%"
    title="即将到期查询结果"
    @close="closed"
  >
    <el-descriptions :column="3" :size="size" border>
      <el-descriptions-item>
        <template #label>
          符合条数
        </template>
        {{ sumResult.count }}条
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          总订单金额
        </template>
        {{ sumResult.sumOrderMonery.toFixed(2) }}元
      </el-descriptions-item>
    </el-descriptions>
    <el-table :data="queryResult" style="width: 100%">
      <el-table-column type="expand">
        <template #default="props">
          <el-descriptions title="订单信息">
            <el-descriptions-item label="受益人："
              >{{ props.row.fullname }}
            </el-descriptions-item>
            <el-descriptions-item label="购买期限："
              >{{ props.row.buyTerm }}个月
            </el-descriptions-item>
            <el-descriptions-item label="年化收益："
              >{{ props.row.yield }}%
            </el-descriptions-item>
            <el-descriptions-item label="付息方式：">
              <template v-if="props.row.payInterestType == 1"
                >每月付息
              </template>
              <template v-else-if="props.row.payInterestType == 2"
                >每3个月付息
              </template>
              <template v-else-if="props.row.payInterestType == 3"
                >每6个月付息
              </template>
              <template v-else-if="props.row.payInterestType == 4"
                >每年付息
              </template>
              <template v-else-if="props.row.payInterestType == 5"
                >按月付息
              </template>
              <template v-else-if="props.row.payInterestType == 6"
                >自然季度付息
              </template>
              <template v-else-if="props.row.payInterestType == 7"
                >自然半年付息
              </template>
              <template v-else-if="props.row.payInterestType == 8"
                >按年付息
              </template>
            </el-descriptions-item>
            <el-descriptions-item label="成立时间："
              >{{ props.row.foundedTime }}
            </el-descriptions-item>
            <el-descriptions-item label="到期时间："
              >{{ props.row.expireTime }}
            </el-descriptions-item>
            <el-descriptions-item label="受益人手机："
              >{{ props.row.phone }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="客户信息">
            <el-descriptions-item label="姓名："
              >{{ props.row.user.fullname }}
            </el-descriptions-item>
            <el-descriptions-item label="手机号："
              >{{ props.row.user.phone }}
            </el-descriptions-item>
            <el-descriptions-item label="性别：">
              <template v-if="props.row.user.sex == 1">男</template>
              <template v-else-if="props.row.user.sex == 2">女</template>
              <template v-else-if="props.row.user.sex == 3">保密 </template>
            </el-descriptions-item>
            <el-descriptions-item label="客户订单：">
              <el-tag
                size="small"
                @click="goUserOrderPage(props.row.user.id)"
                style="cursor: pointer;"
                >点击查看
              </el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </el-table-column>
      <el-table-column prop="product.title" label="产品" />
      <el-table-column
        prop="user.fullname"
        label="客户"
        width="90"
        align="center"
      />
      <el-table-column prop="buyMoney" label="金额" width="60">
        <template #default="scope">
          {{ scope.row.buyMoney / 10000 }}万
        </template>
      </el-table-column>
      <el-table-column
        prop="fullname"
        label="受益人"
        width="90"
        align="center"
      />
      <el-table-column prop="user.buyTerm" label="购买期限" width="100">
        <template #default="scope"> {{ scope.row.buyTerm }}个月 </template>
      </el-table-column>
      <el-table-column
        prop="expireTime"
        label="到期时间"
        width="100"
        align="center"
      />
    </el-table>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getNearExpireOrderList } from '../../../api/home'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  queryKey: {
    type: String,
    required: true
  },
  queryVal: {
    type: String,
    required: true
  },
  dateRange: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const queryResult = ref(null)
const sumResult = ref({
  count: 0,
  sumOrderMonery: 0
})
const queryOrderList = async () => {
  const res = await getNearExpireOrderList({
    key: props.queryKey,
    val: props.queryVal,
    startDate: props.dateRange[0],
    endDate: props.dateRange[1]
  })
  queryResult.value = res
  if (res == null) {
    sumResult.value.count = 0
  } else {
    sumResult.value.count = res.length
    // 遍历统计
    for (const order of queryResult.value) {
      sumResult.value.sumOrderMonery += order.buyMoney
    }
  }
}
queryOrderList()
</script>

<style lang="scss" scoped></style>
