import request from '@/utils/request'

/**
 * 统计近7日跟进数量
 */
export const getFollowRecordSum = () => {
  return request({
    url: '/home/getFollowRecordSum'
  })
}

/**
 * 统计累计成交金额
 */
export const getTradeMoneySum = () => {
  return request({
    url: '/home/getTradeMoneySum'
  })
}

/**
 * 即将付息订单
 */
export const getNearInterestPaymentOrder = data => {
  return request({
    url: '/home/getNearInterestPaymentOrder',
    params: data
  })
}

/**
 * 查询即将付息订单列表
 */
export const getNearInterestPaymentOrderList = data => {
  return request({
    url: '/home/getNearInterestPaymentOrderList',
    params: data
  })
}

/**
 * 查询即将付息订单列表
 */
export const getNearExpireOrderList = data => {
  return request({
    url: '/home/getNearExpireOrderList',
    params: data
  })
}

/**
 * 即将到期订单
 */
export const getNearExpireOrder = data => {
  return request({
    url: '/home/getNearExpireOrder',
    params: data
  })
}

/**
 * 获取系统通知
 * @returns {*}
 */
export const getSystemNotice = () => {
  return request({
    url: '/home/getSystemNotice '
  })
}

/**
 * 客户即将生日
 * @returns {*}
 */
export const getNearUserBirthday = () => {
  return request({
    url: '/home/getNearUserBirthday'
  })
}
