<template>
  <div class="my-container">
    <el-row class="card-wrap">
      <el-col :span="4" class="card">
        <div class="card-body bg-success">
          <div class="pull-right">
            <p class="p1">今日跟进</p>
            <p class="p2">{{ followRecordSum.count }}人</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><edit /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="card">
        <div class="card-body bg-brown">
          <div class="pull-right">
            <p class="p1">在投金额</p>
            <p class="p2">{{ tradeMoneySum.effectCount / 10000 }}万</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><shopping-cart /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="card">
        <div class="card-body bg-blue">
          <div class="pull-right">
            <p class="p1">到期金额</p>
            <p class="p2">{{ tradeMoneySum.invalidCount / 10000 }}万</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><shopping-cart /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="card">
        <div class="card-body bg-purple">
          <div class="pull-right">
            <p class="p1">累计成交</p>
            <p class="p2">{{ tradeMoneySum.count / 10000 }}万</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><shopping-cart /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="card">
        <div class="card-body bg-primary">
          <div class="pull-right">
            <p class="p1">即将付息</p>
            <p class="p2">{{ nearInterestPaymentOrder.total }}单</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><credit-card /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="card">
        <div class="card-body bg-danger">
          <div class="pull-right">
            <p class="p1">即将到期</p>
            <p class="p2">{{ nearExpireOrder.total }}单</p>
          </div>
          <div class="pull-left">
            <span class="img-avatar">
              <el-icon class="icon" size="20"><wallet /></el-icon>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px;">
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>系统通知</span>
            </div>
          </template>
          <div style="height: 180px">{{ systemNotice }}</div>
        </el-card>
      </el-col>
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>客户生日</span>
            </div>
          </template>
          <el-table
            :data="nearUserBirthday"
            style="width: 100%"
            height="180"
            :row-style="{ height: '0' }"
            :cell-style="{ padding: '0' }"
          >
            <el-table-column type="expand">
              <template #default="props">
                <el-descriptions title="客户信息">
                  <el-descriptions-item label="用户ID："
                    >{{ props.row.id }}
                  </el-descriptions-item>
                  <el-descriptions-item label="手机号："
                    >{{ props.row.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item label="客户订单：">
                    <el-tag
                      size="small"
                      @click="goUserOrderPage(props.row.id)"
                      style="cursor: pointer;"
                      >点击查看
                    </el-tag>
                  </el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column prop="fullname" label="姓名" />
            <el-table-column prop="sex" label="性别">
              <template #default="scope">
                <template v-if="scope.row.sex == 1">男</template>
                <template v-else-if="scope.row.sex == 2">女</template>
                <template v-else-if="scope.row.sex == 3">保密</template>
              </template>
            </el-table-column>
            <el-table-column
              prop="birthday"
              label="生日"
              width="100"
              align="center"
            />
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>最近跟进统计</span>
            </div>
          </template>
          <div id="followRecordSum" style="width: 100%;height: 240px;"></div>
        </el-card>
      </el-col>
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>最近成交金额</span>
            </div>
          </template>
          <div id="tradeMoneySum" style="width: 100%;height: 240px;"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>即将付息</span>
            </div>
          </template>
          <div style="margin-bottom: 10px;">
            <el-alert
              title="建议根据手机号查询，避免不同投资者的姓名重复导致混淆"
              type="warning"
              :closable="false"
            />
          </div>

          <el-form :inline="true" size="small" class="demo-form-inline">
            <el-form-item label="">
              <el-select
                v-model="queryNearInterestPaymentOrderForm.key"
                clearable
                placeholder="请选择"
                style="width: 120px"
                @change="
                  () => {
                    queryNearInterestPaymentOrderForm.val = ''
                  }
                "
              >
                <el-option
                  v-for="item in queryOrderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label=""
              v-if="queryNearInterestPaymentOrderForm.key != 'productId'"
            >
              <el-input
                clearable
                v-model="queryNearInterestPaymentOrderForm.val"
                placeholder="请输入搜索内容"
                style="width: 140px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label=""
              v-if="queryNearInterestPaymentOrderForm.key == 'productId'"
            >
              <el-select
                v-model="queryNearInterestPaymentOrderForm.val"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入产品名称"
                style="width: 140px"
                :remote-method="productRemoteMethod"
              >
                <el-option
                  v-for="item in productOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" style="margin-right: 10px;">
              <el-date-picker
                v-model="queryNearInterestPaymentOrderForm.dateRange"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="YYYY/MM/DD"
                value-format="x"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryNearInterestPaymentOrder()"
                >查询
              </el-button>
              <el-button @click="clearQueryNearInterestPaymentOrderForm()"
                >清空</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            :data="nearInterestPaymentOrder.records"
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template #default="props">
                <el-descriptions title="订单信息">
                  <el-descriptions-item label="受益人："
                    >{{ props.row.fullname }}
                  </el-descriptions-item>
                  <el-descriptions-item label="购买期限："
                    >{{ props.row.buyTerm }}个月
                  </el-descriptions-item>
                  <el-descriptions-item label="年化收益："
                    >{{ props.row.yield }}%
                  </el-descriptions-item>
                  <el-descriptions-item label="付息方式：">
                    <template v-if="props.row.payInterestType == 1"
                      >每月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 2"
                      >每3个月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 3"
                      >每6个月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 4"
                      >每年付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 5"
                      >按月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 6"
                      >自然季度付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 7"
                      >自然半年付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 8"
                      >按年付息
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="成立时间："
                    >{{ props.row.foundedTime }}
                  </el-descriptions-item>
                  <el-descriptions-item label="到期时间："
                    >{{ props.row.expireTime }}
                  </el-descriptions-item>
                  <el-descriptions-item label="受益人手机："
                    >{{ props.row.phone }}
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="客户信息">
                  <el-descriptions-item label="姓名："
                    >{{ props.row.user.fullname }}
                  </el-descriptions-item>
                  <el-descriptions-item label="手机号："
                    >{{ props.row.user.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item label="性别：">
                    <template v-if="props.row.user.sex == 1">男</template>
                    <template v-else-if="props.row.user.sex == 2">女</template>
                    <template v-else-if="props.row.user.sex == 3"
                      >保密
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="客户订单：">
                    <el-tag
                      size="small"
                      @click="goUserOrderPage(props.row.user.id)"
                      style="cursor: pointer;"
                      >点击查看
                    </el-tag>
                  </el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column prop="product.title" label="产品" />
            <el-table-column
              prop="user.fullname"
              label="客户"
              width="90"
              align="center"
            />
            <el-table-column prop="buyMoney" label="金额" width="60">
              <template #default="scope">
                {{ scope.row.buyMoney / 10000 }}万
              </template>
            </el-table-column>
            <el-table-column
              prop="fullname"
              label="受益人"
              width="90"
              align="center"
            />
            <el-table-column
              prop="interestPaymentTime.money"
              label="付息金额"
              width="120"
            >
              <template #default="scope">
                {{ scope.row.interestPaymentTime.money }}元
              </template>
            </el-table-column>
            <el-table-column
              prop="interestPaymentTime.time"
              label="付息时间"
              width="100"
              align="center"
            />
          </el-table>

          <div style="text-align: center;padding-top: 20px;">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="nearInterestPaymentOrder.size"
              :current-page="nearInterestPaymentOrder.current"
              :total="nearInterestPaymentOrder.total"
              @size-change="handleNearInterestPaymentOrderSizeChange"
              @current-change="handleNearInterestPaymentOrderCurrentChange"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12" style="padding: 15px;">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>即将到期</span>
            </div>
          </template>
          <div style="margin-bottom: 10px;">
            <el-alert
              title="建议根据手机号查询，避免不同投资者的姓名重复导致混淆"
              type="warning"
              :closable="false"
            />
          </div>

          <el-form :inline="true" size="small" class="demo-form-inline">
            <el-form-item label="">
              <el-select
                v-model="queryNearExpireOrderForm.key"
                clearable
                placeholder="请选择"
                style="width: 120px"
                @change="
                  () => {
                    queryNearExpireOrderForm.val = ''
                  }
                "
              >
                <el-option
                  v-for="item in queryOrderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label=""
              v-if="queryNearExpireOrderForm.key == 'productId'"
            >
              <el-select
                v-model="queryNearExpireOrderForm.val"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入产品名称"
                style="width: 140px"
                :remote-method="productRemoteMethod"
              >
                <el-option
                  v-for="item in productOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label=""
              v-if="queryNearExpireOrderForm.key != 'productId'"
            >
              <el-input
                clearable
                v-model="queryNearExpireOrderForm.val"
                placeholder="请输入搜索内容"
                style="width: 140px"
              ></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-right: 10px;">
              <el-date-picker
                v-model="queryNearExpireOrderForm.dateRange"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="YYYY/MM/DD"
                value-format="x"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryNearExpireOrder()"
                >查询
              </el-button>
              <el-button @click="clearQueryNearExpireOrderForm()"
                >清空
              </el-button>
            </el-form-item>
          </el-form>
          <el-table :data="nearExpireOrder.records" style="width: 100%">
            <el-table-column type="expand">
              <template #default="props">
                <el-descriptions title="订单信息">
                  <el-descriptions-item label="受益人："
                    >{{ props.row.fullname }}
                  </el-descriptions-item>
                  <el-descriptions-item label="购买期限："
                    >{{ props.row.buyTerm }}个月
                  </el-descriptions-item>
                  <el-descriptions-item label="年化收益："
                    >{{ props.row.yield }}%
                  </el-descriptions-item>
                  <el-descriptions-item label="付息方式：">
                    <template v-if="props.row.payInterestType == 1"
                      >每月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 2"
                      >每3个月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 3"
                      >每6个月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 4"
                      >每年付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 5"
                      >按月付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 6"
                      >自然季度付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 7"
                      >自然半年付息
                    </template>
                    <template v-else-if="props.row.payInterestType == 8"
                      >按年付息
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="成立时间："
                    >{{ props.row.foundedTime }}
                  </el-descriptions-item>
                  <el-descriptions-item label="到期时间："
                    >{{ props.row.expireTime }}
                  </el-descriptions-item>
                  <el-descriptions-item label="受益人手机："
                    >{{ props.row.phone }}
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="客户信息">
                  <el-descriptions-item label="姓名："
                    >{{ props.row.user.fullname }}
                  </el-descriptions-item>
                  <el-descriptions-item label="手机号："
                    >{{ props.row.user.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item label="性别：">
                    <template v-if="props.row.user.sex == 1">男</template>
                    <template v-else-if="props.row.user.sex == 2">女</template>
                    <template v-else-if="props.row.user.sex == 3"
                      >保密
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="客户订单：">
                    <el-tag
                      size="small"
                      @click="goUserOrderPage(props.row.user.id)"
                      style="cursor: pointer;"
                      >点击查看
                    </el-tag>
                  </el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column prop="product.title" label="产品" />
            <el-table-column
              prop="user.fullname"
              label="客户"
              width="90"
              align="center"
            />
            <el-table-column prop="buyMoney" label="金额" width="60">
              <template #default="scope">
                {{ scope.row.buyMoney / 10000 }}万
              </template>
            </el-table-column>
            <el-table-column
              prop="fullname"
              label="受益人"
              width="90"
              align="center"
            />
            <el-table-column prop="user.buyTerm" label="购买期限" width="100">
              <template #default="scope">
                {{ scope.row.buyTerm }}个月
              </template>
            </el-table-column>
            <el-table-column
              prop="expireTime"
              label="到期时间"
              width="100"
              align="center"
            />
          </el-table>
          <div style="text-align: center;padding-top: 20px;">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="nearExpireOrder.size"
              :current-page="nearExpireOrder.current"
              :total="nearExpireOrder.total"
              @size-change="handleNearExpireOrderSizeChange"
              @current-change="handleNearExpireOrderCurrentChange"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <query-near-interest-payment-order-dialog
      v-if="showQueryNearInterestPaymentOrderDialog"
      v-model="showQueryNearInterestPaymentOrderDialog"
      :query-key="queryNearInterestPaymentOrderForm.key"
      :query-val="queryNearInterestPaymentOrderForm.val"
      :date-range="queryNearInterestPaymentOrderForm.dateRange"
    >
    </query-near-interest-payment-order-dialog>

    <query-near-expire-order-dialog
      v-if="showNearExpireOrderDialog"
      v-model="showNearExpireOrderDialog"
      :query-key="queryNearExpireOrderForm.key"
      :query-val="queryNearExpireOrderForm.val"
      :date-range="queryNearExpireOrderForm.dateRange"
    ></query-near-expire-order-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { Edit, ShoppingCart, Wallet, CreditCard } from '@element-plus/icons'
import router from '@/router'
import * as echarts from 'echarts'
import {
  getFollowRecordSum,
  getTradeMoneySum,
  getNearInterestPaymentOrder,
  getNearExpireOrder,
  getSystemNotice,
  getNearUserBirthday
} from '@/api/home'
import QueryNearInterestPaymentOrderDialog from './components/QueryNearInterestPaymentOrderDialog'
import { ElMessage } from 'element-plus'
import QueryNearExpireOrderDialog from './components/QueryNearExpireOrderDialog.vue'
import { getProductPage } from '../../api/product'

const systemNotice = ref('')
const getSystemNoticeMethod = async () => {
  const result = await getSystemNotice()
  systemNotice.value = result
}
getSystemNoticeMethod()

const followRecordSum = ref({})
const tradeMoneySum = ref({})
const nearInterestPaymentOrder = ref({
  records: [],
  total: 0,
  current: 1,
  size: 10
})
const nearExpireOrder = ref({
  records: [],
  total: 0,
  current: 1,
  size: 10
})
const nearUserBirthday = ref([])

/**
 * 分页数量改变事件
 */
const handleNearInterestPaymentOrderSizeChange = async size => {
  nearInterestPaymentOrder.value = await getNearInterestPaymentOrder({
    size: size,
    current: nearInterestPaymentOrder.value.current
  })
}
const handleNearInterestPaymentOrderCurrentChange = async current => {
  nearInterestPaymentOrder.value = await getNearInterestPaymentOrder({
    size: nearInterestPaymentOrder.value.size,
    current: current
  })
}

const handleNearExpireOrderSizeChange = async size => {
  nearExpireOrder.value = await getNearExpireOrder({
    size: size,
    current: nearExpireOrder.value.current
  })
}
const handleNearExpireOrderCurrentChange = async current => {
  nearExpireOrder.value = await getNearExpireOrder({
    size: nearExpireOrder.value.size,
    current: current
  })
}

const queryOrderOptions = [
  {
    value: 'orderName',
    label: '受益人姓名'
  },
  {
    value: 'orderPhone',
    label: '受益人手机'
  },
  {
    value: 'userName',
    label: '客户姓名'
  },
  {
    value: 'userPhone',
    label: '客户手机'
  },
  {
    value: 'productId',
    label: '产品名称'
  }
]

const showQueryNearInterestPaymentOrderDialog = ref(false)
const queryNearInterestPaymentOrderForm = ref({
  key: '',
  val: '',
  dateRange: null
})
const clearQueryNearInterestPaymentOrderForm = async => {
  queryNearInterestPaymentOrderForm.value.key = ''
  queryNearInterestPaymentOrderForm.value.val = ''
  queryNearInterestPaymentOrderForm.value.dateRange = null
}
const queryNearInterestPaymentOrder = async () => {
  if (
    queryNearInterestPaymentOrderForm.value.key == null ||
    queryNearInterestPaymentOrderForm.value.key.trim().length === 0
  ) {
    ElMessage({
      message: '请选择查询类型',
      type: 'warning'
    })
    return
  }
  if (
    queryNearInterestPaymentOrderForm.value.val == null ||
    queryNearInterestPaymentOrderForm.value.val.toString().trim().length === 0
  ) {
    ElMessage({
      message: '请输入查询内容',
      type: 'warning'
    })
    return
  }
  if (queryNearInterestPaymentOrderForm.value.dateRange == null) {
    ElMessage({
      message: '请选择日期范围',
      type: 'warning'
    })
    return
  }
  showQueryNearInterestPaymentOrderDialog.value = true
}

const showNearExpireOrderDialog = ref(false)
const queryNearExpireOrderForm = ref({
  key: '',
  val: '',
  dateRange: null
})
const clearQueryNearExpireOrderForm = async => {
  queryNearExpireOrderForm.value.key = ''
  queryNearExpireOrderForm.value.val = ''
  queryNearExpireOrderForm.value.dateRange = null
}
const queryNearExpireOrder = async (key, val) => {
  if (
    queryNearExpireOrderForm.value.key == null ||
    queryNearExpireOrderForm.value.key.trim().length === 0
  ) {
    ElMessage({
      message: '请选择查询类型',
      type: 'warning'
    })
    return
  }
  if (
    queryNearExpireOrderForm.value.val == null ||
    queryNearExpireOrderForm.value.val.toString().trim().length === 0
  ) {
    ElMessage({
      message: '请输入查询内容',
      type: 'warning'
    })
    return
  }
  if (queryNearExpireOrderForm.value.dateRange == null) {
    ElMessage({
      message: '请选择日期范围',
      type: 'warning'
    })
    return
  }
  showNearExpireOrderDialog.value = true
}

/**
 * 产品名称远程搜索
 * @type {ToRef<*[]>}
 */
const productOptions = ref([])
const productRemoteMethod = async query => {
  if (query !== '' && query.length > 1) {
    const productResult = await getProductPage({ title: query })
    productOptions.value = productResult.list
  } else {
    productOptions.value = []
  }
}

const goUserOrderPage = orderId => {
  router.push('/userOrder/manage/' + orderId)
}

onMounted(() => {
  // 基于准备好的dom，初始化echarts实例
  const followRecordSumObj = echarts.init(
    document.getElementById('followRecordSum')
  )
  const tradeMoneySumObj = echarts.init(
    document.getElementById('tradeMoneySum')
  )

  const getData = async () => {
    followRecordSum.value = await getFollowRecordSum()
    tradeMoneySum.value = await getTradeMoneySum()
    nearInterestPaymentOrder.value = await getNearInterestPaymentOrder()
    nearExpireOrder.value = await getNearExpireOrder()
    nearUserBirthday.value = await getNearUserBirthday()
    for (var key in tradeMoneySum.value.list) {
      tradeMoneySum.value.list[key] = tradeMoneySum.value.list[key] / 10000
    }
    console.log(JSON.stringify(tradeMoneySum.value))
    // 绘制图表
    followRecordSumObj.setOption({
      tooltip: {},
      xAxis: {
        data: Object.keys(followRecordSum.value.list)
      },
      yAxis: {},
      series: [
        {
          name: '跟进数',
          type: 'bar',
          data: Object.values(followRecordSum.value.list)
        }
      ]
    })
    tradeMoneySumObj.setOption({
      tooltip: {},
      xAxis: {
        type: 'category',
        data: Object.keys(tradeMoneySum.value.list)
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} 万'
        }
      },
      series: [
        {
          ame: '销售额',
          type: 'line',
          data: Object.values(tradeMoneySum.value.list)
        }
      ]
    })
  }
  getData()
})
</script>

<style lang="scss" scoped>
.my-container {
  .card-wrap {
    .bg-primary {
      background-color: #33cabb !important;
      color: #fff !important;
    }

    .bg-danger {
      background-color: #f96868 !important;
      color: #fff !important;
    }

    .bg-success {
      background-color: #15c377 !important;
      color: #fff !important;
    }

    .bg-purple {
      background-color: #926dde !important;
      color: #fff !important;
    }

    .bg-brown {
      background-color: #dea66d !important;
      color: #fff !important;
    }

    .bg-blue {
      background-color: #6d90de !important;
      color: #fff !important;
    }

    .card {
      padding-right: 15px;
      padding-left: 15px;

      .card-body {
        padding: 24px 24px;
        height: 100px;

        .pull-left {
          float: left !important;
          text-align: center;

          .img-avatar {
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 50%;
            display: inline-block !important;
            background-color: rgba(255, 255, 255, 0.175);
          }

          .icon {
            padding-top: 14px;
          }
        }

        .pull-right {
          float: right !important;
          color: #ffffff !important;

          .p1 {
            margin-top: 0px !important;
            font-size: 14px;
            margin-bottom: 10px;
          }

          .p2 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
